.form-container {
  display: flex;
  flex-direction: column;
  width: 300px;
  margin: auto;
}

.form-container input, .form-container textarea {
  margin: 10px 0;
  padding: 10px;
}

.form-container .agreement {
  display: flex;
  align-items: center;
  margin: 10px 0;
}

.form-container button {
  padding: 10px;
  background-color: #007BFF;
  color: white;
  border: none;
  cursor: pointer;
}

.form-container button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.form-container h1 {
  font-size: 20px;
}

.sosyalikon,
.fa-envelope {
  color: white;
  font-size: 25px;
  position: fixed;
  bottom: 30px;
  right: 30px;
}

.fa-instagram {
  margin-right: 40px;
}

.fa-twitter {
  margin-right: 75px;
}

.iletisim-container {
  max-width: 1000px;
  margin: 4rem auto;
  padding: 0 1.5rem;
}

.iletisim-header {
  text-align: center;
  margin-bottom: 3rem;
}

.iletisim-header h1 {
  font-size: 2.5rem;
  color: var(--accent-1);
  margin-bottom: 0.5rem;
}

.iletisim-header p {
  color: #ccc;
  font-size: 1.1rem;
  max-width: 600px;
  margin: 0 auto;
}

.iletisim-content {
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 3rem;
  background: rgba(20, 20, 20, 0.5);
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
}

.iletisim-social {
  background: rgba(0, 0, 0, 0.3);
  padding: 3rem 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.social-links {
  display: flex;
  gap: 1.5rem;
  margin-bottom: 2rem;
}

.social-links a {
  font-size: 1.8rem;
  color: #fff;
  transition: all 0.3s ease;
}

.social-links a:hover {
  color: var(--primary);
  transform: translateY(-3px);
}

.contact-info p {
  display: flex;
  align-items: center;
  gap: 0.8rem;
  margin-bottom: 1rem;
  color: #ddd;
}

.contact-info i {
  font-size: 1.2rem;
  color: var(--accent-1);
}

.contact-form {
  padding: 3rem 2rem;
}

.form-group {
  margin-bottom: 1.5rem;
}

.form-group label {
  display: block;
  margin-bottom: 0.5rem;
  color: #ddd;
  font-size: 0.95rem;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 0.8rem 1rem;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 6px;
  background: rgba(255, 255, 255, 0.05);
  color: #fff;
  font-size: 1rem;
  transition: all 0.3s ease;
}

.form-group input:focus,
.form-group textarea:focus {
  outline: none;
  border-color: var(--accent-1);
  background: rgba(255, 255, 255, 0.08);
}

.form-group textarea {
  height: 150px;
  resize: vertical;
}

.form-check {
  display: flex;
  align-items: flex-start;
  gap: 0.8rem;
  margin-bottom: 1.5rem;
}

.form-check input {
  margin-top: 0.25rem;
}

.form-check label {
  color: #ddd;
  font-size: 0.9rem;
  cursor: pointer;
}

.form-check label strong {
  color: var(--primary);
  text-decoration: underline;
}

.form-submit-btn {
  display: block;
  width: 100%;
  padding: 1rem;
  border: none;
  border-radius: 6px;
  background: var(--accent-1);
  color: #fff;
  font-size: 1.1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
}

.form-submit-btn:hover:not(:disabled) {
  background: var(--primary);
  transform: translateY(-2px);
}

.form-submit-btn:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.form-message {
  padding: 1rem;
  border-radius: 6px;
  margin-bottom: 1.5rem;
  font-weight: 500;
}

.form-message.success {
  background: rgba(0, 128, 0, 0.2);
  border: 1px solid rgba(0, 128, 0, 0.3);
  color: #98fb98;
}

.form-message.error {
  background: rgba(220, 20, 60, 0.2);
  border: 1px solid rgba(220, 20, 60, 0.3);
  color: #ff6b6b;
}

@media (max-width: 900px) {
  .iletisim-content {
    grid-template-columns: 1fr;
  }
  
  .iletisim-social {
    padding: 2rem;
  }
  
  .social-links {
    justify-content: center;
  }
  
  .contact-info {
    text-align: center;
  }
  
  .contact-info p {
    justify-content: center;
  }
}

@media (max-width: 576px) {
  .iletisim-header h1 {
    font-size: 2rem;
  }
  
  .iletisim-header p {
    font-size: 1rem;
  }
  
  .contact-form {
    padding: 2rem 1.5rem;
  }
}

