
.kapsam {
    max-width: 900px;
    margin: 0 auto;
    padding: 2rem;
    color: #fff;
    text-align: justify;
}

.kapsam h1 {
    font-size: 1.7rem;
    font-weight: 800;
    margin-bottom: 1rem;
    color: var(--clr-1);
}

.kapsam p {
    font-size: 1rem;
    line-height: 1.6;
    margin-bottom: 2rem;
}

@media (max-width: 768px) {
    .kapsam {
        margin-top: 6rem;
        padding: 1.5rem;
    }

    .kapsam h1 {
        font-size: 1.8rem;
    }

    .kapsam p {
        font-size: 1rem;
    }
}

.linkSt {
    color: var(--clr-1); 
    text-decoration: none; 
  }
  
  .linkSt:hover {
    color: var(--clr-2); 
  }
  