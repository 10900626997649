.navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: rgba(24, 24, 24, 0.95);
  backdrop-filter: blur(10px);
  z-index: 1000;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.navbar.scrolled {
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  max-width: 1400px;
  margin: 0 auto;
}

.navbar h1 {
  color: var(--primary);
  font-size: 1.4rem;
  text-transform: uppercase;
  font-weight: 700;
  margin: 0;
  white-space: nowrap;
}

.navbar-menu {
  display: flex;
  align-items: center;
  gap: 2rem;
}

.navbar a {
  color: #fff;
  text-decoration: none;
  font-size: 1.1rem;
  transition: all 0.3s ease;
  position: relative;
}

.navbar a::after {
  content: '';
  position: absolute;
  width: 0;
  height: 2px;
  bottom: -5px;
  left: 0;
  background-color: currentColor;
  transition: width 0.3s ease;
}

.navbar a:hover::after {
  width: 100%;
}

.navbar .derg {
  color: var(--primary);
}

.navbar .derg:hover {
  color: var(--accent-1);
}

.navbar .ana:hover {
  color: var(--primary);
}

.navbar .ama:hover {
  color: var(--accent-1);
}

.navbar .kapa:hover {
  color: var(--accent-2);
}

.navbar .duy:hover {
  color: var(--secondary);
}

.hamburger {
  display: none;
  flex-direction: column;
  justify-content: space-between;
  width: 30px;
  height: 20px;
  cursor: pointer;
}

.hamburger span {
  display: block;
  width: 100%;
  height: 2px;
  background-color: #fff;
  transition: all 0.3s ease;
}

@media (max-width: 1024px) {
  .navbar-container {
    padding: 1rem;
  }
}

@media (max-width: 768px) {
  .hamburger {
    display: flex;
    z-index: 1001;
  }
  
  .navbar-menu {
    position: fixed;
    top: 0;
    right: -100%;
    width: 70%;
    height: 100vh;
    background: rgba(24, 24, 24, 0.98);
    backdrop-filter: blur(10px);
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: right 0.3s ease;
    z-index: 1000;
  }
  
  .navbar-menu.active {
    right: 0;
  }
  
  .navbar h1 {
    font-size: 1.2rem;
  }
  
  .hamburger.active span:nth-child(1) {
    transform: rotate(45deg) translate(5px, 5px);
  }
  
  .hamburger.active span:nth-child(2) {
    opacity: 0;
  }
  
  .hamburger.active span:nth-child(3) {
    transform: rotate(-45deg) translate(7px, -7px);
  }
}
