/* Dergi.css - Complete Magazine Carousel Styles */
.dergi-section {
  padding: 3rem 1rem;
  max-width: 1400px;
  margin: 0 auto;
  overflow-x: hidden;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* Removed solid background - will now use the site's gradient background */
  background: rgba(0, 0, 0, 0.3);
  border-radius: var(--border-radius);
  margin: 2rem 0;
  overflow: hidden;
}

.carousel-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3rem 5rem;
  min-height: 550px;
  width: 100%;
}

.dergi-carousel {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  perspective: 2000px;
  transform-style: preserve-3d;
  position: relative;
}

.dergi-card {
  background: rgba(40, 40, 40, 0.9);
  border-radius: var(--border-radius);
  overflow: hidden;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
  transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1);
  position: absolute;
  width: 300px;
  cursor: pointer;
  transform: 
    translateX(calc(var(--position) * 200px))
    scale(calc(1 - var(--distance) * 0.15))
    translateZ(calc(var(--distance) * -100px));
  filter: blur(calc(var(--distance) * 1px)) 
          brightness(calc(1 - var(--distance) * 0.1));
  z-index: calc(10 - var(--distance));
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.dergi-card.active {
  width: 350px;
  transform: translateX(0) scale(1) translateZ(0);
  filter: blur(0) brightness(1);
  z-index: 20;
  position: relative;
  box-shadow: 0 15px 40px rgba(0, 0, 0, 0.4);
}

.dergi-image-container {
  position: relative;
  overflow: hidden;
  height: 450px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.dergi-card img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.5s ease;
}

.dergi-card.active img {
  transform: scale(1.03);
}

.dergi-info {
  padding: 1.8rem;
  text-align: center;
  background: rgba(20, 20, 20, 0.7);
}

.dergi-info h3 {
  color: var(--primary);
  font-size: 1.3rem;
  margin-bottom: 0.8rem;
  font-weight: 700;
}

.dergi-info h4 {
  font-size: 1.1rem;
  color: var(--light);
  font-weight: 500;
  margin-bottom: 1.8rem;
  line-height: 1.5;
}

.dergi-info .btn {
  width: 80%;
  margin: 0 auto;
  padding: 0.8rem;
  font-size: 1rem;
  background: var(--primary);
  color: var(--darker);
}

.dergi-info .btn:hover {
  background: var(--secondary);
  color: var(--light);
  transform: translateY(-3px);
}

.carousel-nav {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(0, 0, 0, 0.7);
  border: 2px solid var(--primary);
  border-radius: 50%;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--primary);
  font-size: 1.5rem;
  cursor: pointer;
  z-index: 30;
  transition: var(--transition);
  opacity: 0.8;
}

.carousel-nav:hover {
  background: var(--primary);
  color: var(--darker);
  transform: translateY(-50%) scale(1.1);
  opacity: 1;
}

.carousel-nav:disabled {
  opacity: 0.2;
  cursor: not-allowed;
  border-color: var(--light);
}

.carousel-prev {
  left: 2rem;
}

.carousel-next {
  right: 2rem;
}

.dergi-dots {
  display: flex;
  justify-content: center;
  gap: 1.2rem;
  margin-top: 2rem;
  padding-bottom: 1.5rem;
}

.dergi-dot {
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.3);
  cursor: pointer;
  transition: var(--transition);
  border: 1px solid transparent;
}

.dergi-dot.active {
  background: var(--primary);
  transform: scale(1.3);
  border-color: rgba(255, 255, 255, 0.5);
}

.dergi-dot:hover:not(.active) {
  background: rgba(255, 255, 255, 0.5);
}

/* Responsive Design */
@media (max-width: 1200px) {
  .carousel-container {
    padding: 3rem 4rem;
  }
  
  .dergi-card {
    width: 280px;
    transform: 
      translateX(calc(var(--position) * 180px));
  }
  
  .dergi-card.active {
    width: 320px;
  }
  
  .dergi-image-container {
    height: 420px;
  }
}

@media (max-width: 992px) {
  .carousel-container {
    padding: 2rem 3rem;
    min-height: 500px;
  }
  
  .dergi-card {
    width: 250px;
    transform: 
      translateX(calc(var(--position) * 150px));
  }
  
  .dergi-card.active {
    width: 280px;
  }
  
  .dergi-image-container {
    height: 380px;
  }
  
  .carousel-nav {
    width: 50px;
    height: 50px;
  }
}

@media (max-width: 768px) {
  .carousel-container {
    padding: 2rem;
    min-height: 450px;
  }
  
  .dergi-card {
    width: 220px;
    transform: 
      translateX(calc(var(--position) * 120px));
  }
  
  .dergi-card.active {
    width: 240px;
  }
  
  .dergi-image-container {
    height: 340px;
  }
  
  .dergi-info {
    padding: 1.5rem;
  }
  
  .dergi-info h3 {
    font-size: 1.2rem;
  }
  
  .dergi-info h4 {
    font-size: 1rem;
    margin-bottom: 1.5rem;
  }
  
  .carousel-nav {
    width: 45px;
    height: 45px;
    font-size: 1.2rem;
  }
  
  .carousel-prev {
    left: 1rem;
  }
  
  .carousel-next {
    right: 1rem;
  }
}

@media (max-width: 576px) {
  .carousel-container {
    padding: 2rem 1rem;
    min-height: 400px;
  }
  
  .dergi-card {
    width: 190px;
    transform: 
      translateX(calc(var(--position) * 90px));
  }
  
  .dergi-card.active {
    width: 210px;
  }
  
  .dergi-image-container {
    height: 300px;
  }
  
  .dergi-info {
    padding: 1.2rem;
  }
  
  .dergi-info h3 {
    font-size: 1.1rem;
  }
  
  .dergi-info h4 {
    font-size: 0.9rem;
    margin-bottom: 1.2rem;
  }
  
  .dergi-info .btn {
    padding: 0.6rem;
    font-size: 0.9rem;
  }
  
  .carousel-nav {
    width: 40px;
    height: 40px;
  }
  
  .dergi-dots {
    gap: 1rem;
    margin-top: 1.5rem;
  }
  
  .dergi-dot {
    width: 12px;
    height: 12px;
  }
}