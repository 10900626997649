

.modal-overlay {
    position: fixed;
    top: 40px;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.4);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal {
    background: rgb(57, 57, 57);
    padding: 30px;
    border-radius: 8px;
    width: 80%;
    max-width: 500px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    overflow-y: auto;
    max-height: 90vh;
  }
  
  .modal h2 {
    color: rgb(241, 241, 241);
    margin-top: 30px;
  }
  
  .modal ul {
    padding-left: 10px;
    padding-right: 5px;
  }

  .modal li {
    color: rgb(237, 237, 237);
  }
  
  .modal button {
    display: block;
    margin-top: 20px;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    background-color: var(--clr-1);
    color: rgb(33, 33, 33);
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .modal button:hover {
    background-color: #0056b3;
  }
  
  @media screen and (max-width: 600px) {
    .modal {
      padding: 15px;
      width: 90%;
    }
  }
  
