:root {
  --primary: #f1d861;
  --secondary: #3e86d8;
  --accent-1: #b97ee7;
  --accent-2: #99e398;
  --dark: #292929;
  --darker: #1a1a1a;
  --light: #f8f8f8;
  --transition: all 0.3s ease;
  --shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  --border-radius: 8px;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Alegreya', serif;
  background: linear-gradient(45deg, #2f2f2f, #0f0f0f, #27202f, #2a1a22);
  background-size: 200% 200%;
  animation: gradient 15s ease infinite;
  color: var(--light);
  min-height: 100vh;
  padding-top: 4.5rem;
}

@keyframes gradient {
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
}

h1, h2, h3, h4, h5 {
  font-weight: 700;
  line-height: 1.2;
}

p {
  line-height: 1.6;
}

a {
  text-decoration: none;
  color: var(--primary);
  transition: var(--transition);
}

a:hover {
  color: var(--secondary);
}

.container {
  width: 100%;
  max-width: 1200px;
  padding: 0 1rem;
  margin: 0 auto;
}

.section {
  padding: 4rem 0;
}

.btn {
  display: inline-block;
  padding: 0.75rem 1.5rem;
  background-color: var(--primary);
  color: var(--darker);
  border: none;
  border-radius: var(--border-radius);
  font-weight: 600;
  cursor: pointer;
  transition: var(--transition);
}

.btn:hover {
  background-color: var(--secondary);
  color: var(--light);
  transform: translateY(-2px);
}

@media (max-width: 768px) {
  body {
    padding-top: 6rem;
  }
}