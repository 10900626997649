.duyurular-container {
    max-width: 900px;
    margin: 30px auto;
    padding: 20px;
  }
  
  .duyurular-title {
    font-size: 22px;
    text-align: center;
    margin-bottom: 40px;
  }
  
  .duyurular-list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
  }
  
  .duyuru {
    background-color: #f7f7f7;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 2px 4px rgba(255, 0, 0, 0.1);
  }
  
  .duyuru-baslik {
    font-size: 18px;
    margin-bottom: 10px;
    color: black;
  }
  
  .duyuru-tarih {
    font-size: 12px;
    color: #777;
    margin-bottom: 5px;
  }
  
  .duyuru-aciklama {
    font-size: 14px;
    line-height: 1.5;
    color: black;
  }
  
  @media (max-width: 767px) {
    .duyurular-container {
        margin-top: 60px;

      }
    .duyurular-list {
      grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
    }
  }

.duyurular-section {
  padding: 4rem 1rem;
  max-width: 1200px;
  margin: 0 auto;
}

.duyurular-header {
  text-align: center;
  margin-bottom: 3rem;
}

.duyurular-header h1 {
  font-size: 2.5rem;
  color: var(--secondary);
  margin-bottom: 0.5rem;
}

.duyurular-header p {
  font-size: 1.2rem;
  color: #ccc;
  max-width: 600px;
  margin: 0 auto;
}

.duyurular-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  gap: 2rem;
}

.duyuru-card {
  background: rgba(255, 255, 255, 0.05);
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
  transition: all 0.3s ease;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  position: relative;
  border-left: 4px solid var(--secondary);
}

.duyuru-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 12px 25px rgba(0, 0, 0, 0.25);
  background: rgba(255, 255, 255, 0.08);
}

.duyuru-card.okundu {
  border-left: 4px solid var(--accent-2);
  opacity: 0.7;
}

.duyuru-card.okundu .duyuru-status {
  background-color: var(--accent-2);
}

.duyuru-content {
  padding: 1.5rem;
  flex: 1;
}

.duyuru-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.8rem;
}

.duyuru-status {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: var(--secondary);
}

.duyuru-tarih {
  font-size: 0.85rem;
  color: #aaa;
}

.duyuru-baslik {
  font-size: 1.3rem;
  margin-bottom: 0.8rem;
  color: #fff;
}

.duyuru-aciklama {
  font-size: 1rem;
  color: #ddd;
  line-height: 1.5;
}

.duyuru-footer {
  padding: 0 1.5rem 1.5rem;
  display: flex;
  justify-content: flex-end;
}

.duyuru-btn {
  background: transparent;
  border: 1px solid var(--secondary);
  color: var(--secondary);
  padding: 0.5rem 1rem;
  border-radius: 20px;
  font-size: 0.9rem;
  cursor: pointer;
  transition: all 0.3s ease;
}

.duyuru-btn:hover {
  background: var(--secondary);
  color: var(--darker);
}

@media (max-width: 768px) {
  .duyurular-section {
    padding: 3rem 1rem;
  }

  .duyurular-header h1 {
    font-size: 2rem;
  }

  .duyurular-header p {
    font-size: 1rem;
  }

  .duyurular-container {
    grid-template-columns: 1fr;
    max-width: 500px;
    margin: 0 auto;
  }
}
